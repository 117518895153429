import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Link from "next/link";
import Text from "reusecore/src/elements/Text";
import Image from "reusecore/src/elements/Image";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import FeatureBlock from "../../../components/FeatureBlock";
import Container from "../../../components/UI/Container";
import BannerWrapper, {
  DiscountLabel,
  BannerObject
} from "./bannerSection.style";

import VideoSection from 'common/src/containers/Agency/VideoSection';
import '@redq/reuse-modal/es/index.css';

import BannerObject1 from "../../../assets/image/saas/banner/bannerObject1.png";
import BannerObject2 from "../../../assets/image/saas/banner/bannerObject2.png";

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
  btnGhost,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <Link href="https://matreso.bpifrance.fr/sign-up/login">
        <a target="_blank">
          <Button title="Se connecter" {...btnStyle} />
        </a>
      </Link>
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <DiscountLabel>
              <Text content="Disponible en " {...discountText} />
              <Text content="version gratuite" {...discountAmount} />
            </DiscountLabel>
            <FeatureBlock
              title={
                <Heading
                  content={`Pilotez votre trésorerie, tout simplement.`}
                  {...title}
                />
              }
              description={
                <Text
                  content="Profitez en avant-première du nouvel assistant financier de Bpifrance, depuis votre ordinateur ou votre smartphone, à partir de données agrégées par notre partenaire Budget Insight."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div className="objectWrapper">
          <Image src={BannerObject1} alt="BannerObject1" />
          <div className="dashboardWrapper">
            <VideoSection />
          </div>
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center"
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: [1, "70%", "50%", "45%"]
  },
  title: {
    fontSize: ['22px', '34px', '30px', '55px'],
    fontWeight: "700",
    color: "#5E514D",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.2"
  },
  description: {
    fontSize: "16px",
    color: "#5E514D",
    lineHeight: "1.75",
    mb: "0"
  },
  btnStyle: {
    minWidth: ["120px", "120px", "120px", "156px"],
    fontSize: ["13px", "14px"],
    fontWeight: "500",
    colors: "primaryWithBg",
    mr: "18px"
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#5E514D",
    backgroundColor: "#E5E5E5",
  },
  discountAmount: {
    fontSize: "14px",
    color: "#FFCD00",
    mb: 0,
    as: "span",
    mr: "0.4em",
    fontWeight: 700
  },
  discountText: {
    fontSize: ["13px", "14px"],
    color: "#5E514D",
    mb: 0,
    as: "span",
    fontWeight: 700
  }
};

export default BannerSection;
