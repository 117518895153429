import React, { Fragment } from "react";
import Image from "reusecore/src/elements/Image";
import PropTypes from "prop-types";
import Link from "next/link";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Container from "../../../components/UI/Container";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon
} from "../../../components/Accordion";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/entypo/plus";
import { minus } from "react-icons-kit/entypo/minus";
import FaqSectionWrapper from "./faqSection.style";

import { Faq } from "../../../data/Saas";

const FaqSection = ({
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  titleStyle,
  descriptionStyle,
  buttonWrapper,
  button,
  btnStyle,
  imageStyle
}) => {
  return (
    <FaqSectionWrapper id="faq_section">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} />
          <Heading {...sectionTitle} />
        </Box>
        <Box className="row">
          <Accordion>
            <Fragment>
              {Faq.map((faqItem, index) => (
                <AccordionItem key={index} expanded={faqItem.expend}>
                  <Fragment>
                    <AccordionTitle>
                      <Fragment>
                        <Heading content={faqItem.title} {...titleStyle} />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={minus} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={plus} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </Fragment>
                    </AccordionTitle>
                    <AccordionBody>
                      <Text
                        content={faqItem.description}
                        {...descriptionStyle}
                      />
                    </AccordionBody>
                    <AccordionBody>
                      <Text
                        content={faqItem.description2}
                        {...descriptionStyle}
                      />
                    </AccordionBody>
                    <AccordionBody>
                      <Image 
                        src={faqItem.image} 
                        alt={faqItem.alternatetext}
                        {...imageStyle}
                      />
                    </AccordionBody>
                  </Fragment>
                </AccordionItem>
              ))}
            </Fragment>
          </Accordion>
          <Box {...buttonWrapper} id="contact2">
            <Link href="mailto:matreso@bpifrance.fr">
              <a>
                <Button title="NOUS CONTACTER" {...btnStyle} />
              </a>
            </Link>
          </Box>
        </Box>
      </Container>
    </FaqSectionWrapper>
  );
};

// FaqSection style props
FaqSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object
};

// FaqSection default style
FaqSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: "56px"
  },
  //sub section default style
  
  //sectionSubTitle: {
  //  content: 'FAQ',
  //  as: 'span',
  //  display: 'block',
  //  textAlign: 'center',
  //  fontSize: '14px',
  //  letterSpacing: '0.15em',
  //  fontWeight: '700',
  //  color: '#FFCD00',
  //  mb: '5px',
  //},

  // section title default style
  sectionTitle: {
    content: "Questions fréquentes",
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "700",
    color: "#5E514D",
    letterSpacing: "-0.025em",
    mb: "0"
  },
  // accordion title default style
  titleStyle: {
    fontSize: ["16px", "19px"],
    fontWeight: "500",
    color: "#5E514D",
    letterSpacing: "-0.025em",
    mb: "0"
  },
  // accordion description default style
  descriptionStyle: {
    fontSize: "15px",
    color: "#5E514D",
    lineHeight: "1.75",
    mb: "0",
    fontWeight: "400"
  },
  buttonWrapper: {
    mt: `${11}`,
    flexBox: true,
    justifyContent: "center"
  },
  button: {
    title: "NOUS CONTACTER",
    type: "button",
    fontSize: `${2}`,
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primary",
    height: `${4}`
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  imageStyle: {
    maxHeight: "200px",
    maxWidth: "200px",
  },
};

export default FaqSection;
