import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Saas/saas.style';
import Navbar from 'common/src/containers/Saas/Navbar';
import BannerSection from 'common/src/containers/Saas/BannerSection';
import ServiceFeatures from 'common/src/containers/Saas/ServiceFeatures'
import Section1 from 'common/src/containers/Saas/Section1';
import Section2 from 'common/src/containers/Saas/Section2';
import Section3 from 'common/src/containers/Saas/Section3';
import Section4 from 'common/src/containers/Saas/Section4';
import FaqSection from 'common/src/containers/Saas/FaqSection';
import Footer from 'common/src/containers/Saas/Footer';
import TimelineSection from 'common/src/containers/Saas/TimelineSection';
import Testimonials from 'common/src/containers/Hosting/Testimonials';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';


export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="Ma Tréso" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <ServiceFeatures />
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <TimelineSection />
          <Testimonials />
          <FaqSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
